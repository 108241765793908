import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { app, functions } from "./firebase";

function PaymentStripe() {
  const [amount, setAmount] = useState(100);
  const [currency, setCurrency] = useState("USD");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const stripeChecoutHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    const stripeCheckout = await functions.httpsCallable(
      "createStripeCheckout"
    );
    const stripePromise = await loadStripe(
      "pk_live_bSVkbVMgM0TCwFt1OowmDcXc005fkRHe7v"
    );

    stripeCheckout({
      currency: currency,
      amount: amount * 100,
      sUrl: "https://bigmoviezoo.app/paymentSuccess",
      cUrl: "https://bigmoviezoo.app/paymentFailed",
    })
      .then((result) => {
        setLoading(false);
        // Read result of the Cloud Function.
        /** @type {any} */
        //console.log(result);
        const data = result.data;
        const sessionId = data.id;
        stripePromise.redirectToCheckout({ sessionId: sessionId });
      })
      .catch((error) => {
        // Getting the Error details.
        setLoading(false);
        const code = error.code;
        const message = error.message;
        const details = error.details;
        setError(error.message);
        // ...
        //console.log(error);
      });
  };

  //   <input type="text" placeholder="First Name" onChange={e => setfName(e.target.value)} />
  //     <input type="text" placeholder="Last Name" onChange={e => setlName(e.target.value)} />
  //     <input type="text" placeholder="Phone" onChange={e => setPhone(e.target.value)} />

  const selectVal = (e) => {
    //alert(e.target.value);
    setCurrency(e.target.value);
  };

  return (
    <div className="container" style={{ minHeight: "300px" }}>
      <div className="row align-items-center justify-content-center">
        <div className="col-auto w-100">
          {loading ? (
            <div className="alert alert-primary" role="alert">
              Loading.....
            </div>
          ) : (
            ""
          )}
          {error != "" ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            ""
          )}
        </div>
        {/*<div className="col-auto mt-3 dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown button
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" href="#">
                Action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Another action
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </li>
          </ul>
          </div> */}
        <div className="col-auto mt-3">
          <select className="form-control" onChange={(e) => selectVal(e)}>
            <option value="USD">USD</option>
            <option value="INR">INR</option>
            <option value="EUR">EUR</option>
            <option value="CAD">CAD</option>
            <option value="AUD">GBP</option>
            <option value="GBP">CAD</option>
          </select>
        </div>
        <div className="col-auto mt-3">
          <input
            id="exampleFormControlInput1"
            type="text"
            className="form-control"
            placeholder="Amount"
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="col-auto mt-1">
          <button
            className="btn btn-primary align-self-center"
            onClick={stripeChecoutHandler}
            style={{ minWidth: "150px" }}
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentStripe;
