import logo from "./logo.svg";
import "./App.css";
import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Link,
} from "react-router-dom";

import Home from "./home";
import PaymentStripe from "./payment";

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route
            path="/paymentSuccess"
            exact
            component={() => (
              <div className="container">
                <div className="alert alert-success" role="alert">
                  Payment Successfull!!!
                </div>
                <Link to="/payment">back</Link>
              </div>
            )}
          />

          <Route
            path="/paymentFailed"
            exact
            component={() => (
              <div className="container">
                <div className="alert alert-danger" role="alert">
                  Payment Failed!!!
                </div>
                <Link to="/payment">back</Link>
              </div>
            )}
          />
          <Route path="/payment" component={() => <PaymentStripe />} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
