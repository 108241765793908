import React from "react";
import "./App.css";
function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://play.google.com/store/apps/details?id=com.videostreaming.bmz"
        >
          <img
            src="https://push-1729.5centscdn.com/http/Raat-Ki-Rani-Begum-Jaan_Hindi_L.jpg"
            alt="logo"
          />
        </a>
      </header>
    </div>
  );
}

export default Home;
